// variables.js
export const footerNavigationHeight = 48;
export const userProfileFooterHeight = 48;
export const iPhoneXSeriesFooterPaddingInPWA = 34;
export const headerHeight = 64;
export const pinnedNotificationOnDesktopHeight = 60;
export const pinnedNotificationOnTabletHeight = 48;
export const pinnedNotificationOnMobileHeight = 70;
export const color = {
  black: '#191919',
  white: '#ffffff',
  grey: {
    100: '#F6F6F6',
    300: '#C8C8C8',
    500: '#9B9B9B',
    700: '#646464',
    900: '#232323',
  },
  /**
   * @type {Neutral}
   * @property {string} 0 - #191919
   * @property {string} 5 - #1e1e1e
   * @property {string} 10 - #232323
   * @property {string} 20 - #474747
   * @property {string} 30 - #646464
   * @property {string} 45 - #808080
   * @property {string} 55 - #9b9b9b
   * @property {string} 65 - #aeaeae
   * @property {string} 75 - #c8c8c8
   * @property {string} 85 - #dddddd
   * @property {string} 95 - #f6f6f6
   * @property {string} 100 - #ffffff
   */
  neutral: {
    0: '#191919',
    5: '#1e1e1e',
    10: '#232323',
    20: '#474747',
    '20_alpha_40': '#47474766', // opacity 40% for color
    30: '#646464',
    45: '#808080',
    55: '#9b9b9b',
    65: '#aeaeae',
    75: '#c8c8c8',
    85: '#dddddd',
    95: '#f6f6f6',
    100: '#ffffff',
  },
  blue: {
    10: '#EDF4FC',
    40: '#B7D3F3',
    80: '#6EA6E8',
    100: '#4A90E2',
    140: '#2C5688',
  },
  tealBlue: '#00d2be',
  peach: '#FF7878',
  sky: '#60c4e8',
  yellow: '#FFD100',
  yellowTan: '#fee573',
  green: '#83CC38',
  lime: '#82ff8c',
  red: '#cf0404',
  purple: '#734eff',
  purple_alpha_40: '#734eff66', // opacity 40% for color
  carmine: '#d41872',
  salmon: '#ff7878',
  softBlue: '#5e81f4',
  blushPink: '#fd8a94',
  lovense: '#f6418d',
};
export const textColor = {
  black: color.grey['700'],
  white: color.white,
  grey: color.grey['300'],
  primary: color.tealBlue,
  secondary: color.sky,
  danger: color.red,
  success: color.lime,
  softBlue: color.softBlue,
  blushPink: color.blushPink,
};
export const toastrColor = {
  success: 'rgba(0, 210, 190, 0.6)',
  error: 'rgba(207, 4, 4, 0.6)',
  info: 'rgba(255, 255, 255, 0.8)',
};

export const DotSize = {
  SIZE_0: 0,
  SIZE_8: 8,
  SIZE_12: 12,
  SIZE_16: 16,
  SIZE_20: 20,
  SIZE_26: 26,
  SIZE_28: 28,
  SIZE_32: 32,
};

export const breakpoint = {
  tablet: 1024,
  mobile: 768,
};

export const modalBackgroundOpacity = 0.4;
export const loadingIconOpacity = 0.6;
export const scrollBarHiddenPadding = 40;
export const tabHeight = 40;
export const desktopTabHeight = 48;

export const mobileAspectRatioWidth = 320;
export const mobileAspectRatioHeight = 454;

export const flixRowAutoPlayWidth = 520;

export const showcasePlayerMobileHeight = 240;
export const showcaseMobileWidth = 320;
export const showcaseMobileHeight = 240;
export const showcasePlayerTabletHeight = 300;
export const showcaseTabletWidth = 400;
export const showcaseTabletHeight = 300;
export const showcasePlayerDesktopHeight = 522;
export const showcaseDesktopWidth = 696;
export const showcaseDesktopHeight = 522;
// each side of item gap is 6px, the total of item gap is 12px.
export const showcaseItemGap = 12;
// each side of container is 6px, the total of container is 12px.
export const showcaseContainerGap = 12;
export const showcaseMobileMinimumItemAmount = 3;
export const showcaseDesktopMinimumItemAmount = 5;
export const showcaseSwipeTransitionSeconds = 0.35;

export const popupNotificationTopAndBottomMaximumWidth = 480;
export const popupNotificationTopAndBottomMaximumWidthInDesktop = 360;
export const popupNotificationTopAndBottomHeight = 52;
export const popupNotificationTopAndBottomHorizontalPadding = 8;
export const popupNotificationCenterMaximumWidthInPortrait = 400;
export const popupNotificationCenterMaximumHeightInLandscape = 360;
export const popupNotificationCenterHorizontalPaddingInPortrait = 24;
export const popupNotificationCenterVerticalPaddingInLandscape = 24;
export const popupNotificationCenterCloseButtonHeight = 40;
export const popupNotificationCenterContentHeightRatioInPortrait = 1.4678;
export const popupNotificationCenterContentWidthRatioInLandscape = 1.8118;
export const popupNotificationFullscreenContentHorizontalPaddingInDesktop = 32;
export const popupNotificationFullscreenContentVerticalPaddingInDesktop = 32;

export const chatBannerMaximumWidth = 359;
export const chatBannerMaximumHeight = 52;

export const freeZoneFlixCardWidth = 280;
export const freeZoneFlixCardColumnGap = 12;
export const freeZoneFlixCardPadding = 16;

export const searchSwaggerFilterHeight = 36;
export const searchStoryMessageCardWidth = 148;
export const searchStoryMessageCardWidthTablet = 122;
export const searchStoryMessageCardWidthMobile = 104;
export const searchStoryMessageCardsColumnGap = 8;
export const searchStoryMessageCardsHorizontalTotalPadding = 32;
export const searchBarHeight = 100;

export const headerZIndex = 100;

export const flixPlayerZIndex = {
  overlay: 1,
  controlDisplayedOnMobileNotification: 2,
  mask: 3,
  gestureDetector: 4,
  replayButton: 5,
  notification: 5,
  actionAnimation: 5,
  trailerBlocker: 6,
  moreButton: 7,
  backButton: 7,
  unlockButton: 6,
};

export const shopModalWidth = 360;
export const diamondPackMaxWidth = 472;
export const shopZIndex = {
  default: {},
  underShopRoot: {
    currencySelector: 3,
    paymentSourceSelector: 2,
    OrderSummary: 1,
  },
};

export const sortMenuZIndex = 10;
export const filterMenuZIndex = 10;

export const sicBoHeaderHeight = 36;
export const sicBoHeight = 264;
export const sicBoGameIdWidth = 160;
export const sicBoZIndex = 1;

export const gameMenuHeight = 172;

export const dragonTigerFightHeaderHeight = 36;
export const dragonTigerFightHeight = 360;
export const dragonTigerFightZIndex = 1;

export const giftSlotHeaderHeight = 36;
export const giftSlotHeight = 212;
export const giftSlotZIndex = 1;
